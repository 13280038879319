<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<template>
  <div class="payment-item">
    <div class="date">缴费时间 {{ item.date }}</div>
    <div class="bg">
      <div class="line">
        <div class="l1">实缴金额：<span class="c1">{{ item.total }}</span></div>
        <div class="l2">欠缴总金额：<span class="c2">{{ item.fee }}</span></div>
      </div>
      <div class="line c3">
        <div class="l1">录入人：{{ item.action }}</div>
        <div class="l2">录入时间：{{ item.time }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.payment-item {
  font-size: 14rem;
  color: #333;
  .date {
    margin-bottom: 10rem;
    font-weight: 600;
  }
  .bg {
    // width: 420rem;
    padding: 10rem 12rem;
    background-color: #F6F9FF;
    border-radius: 4rem;
  }
  .line {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 10rem;
    }
  }
  .c1 {
    color: #1C4EFD;
    font-weight: 600;
  }
  .c2 {
    color: #F6AD3C;
    font-weight: 600;
  }
  .c3 {
    color: #999;
  }
  .l1 {
    width: 180rem;
  }
}
</style>
